import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link, MenuList, MenuItem, ListSubheader } from '@material-ui/core';
import { Menu, IconButton, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { style } from '@badger/design-system';

import { ReactComponent as More } from 'assets/icons/more.svg';
import { SocialProfileDialog } from 'dialogs';

import Analytics from 'analytics';
import { LoginRegisterDialog } from 'components/navBar';
import { useGetUserWithProfile } from 'social.gql';
import { Role } from 'gql/types/globals';
import { InstructionsDialog } from '../socialSpace/InstructionsDialog';
import { NavBarSpacesSubMenu } from './NavBarSpacesSubMenu';
import { useLogout, useRole } from 'auth';

const useStyles = makeStyles({
  menuButtonOpen: {
    color: style.colors.secondary,
  },
  menuButtonClosed: {
    color: 'white',
  },
});

export const FEEDBACK_URL = 'https://form.questionscout.com/5eee59bd67c176a66fe2d0de';
export const SUPPORT_URL = 'https://www.humby.io/support/kamerazugriff-erlauben';

interface Props {
  spaceId?: string;
  showInstructions?: boolean;
}

export function NavBarMenu(props: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const [editProfileDialogOpen, setEditProfileDialogOpen] = React.useState(false);
  const [loginRegisterDialogOpen, setLoginRegisterDialogOpen] = React.useState(false);
  const [instructionsDialogOpen, setInstructionsDialogOpen] = React.useState(false);

  const { data } = useGetUserWithProfile();
  const logout = useLogout();

  const role = useRole();

  const isLoggedIn = React.useMemo(() => (role ?? Role.NONE) >= Role.VISITOR, [role]);
  const email = data?.user?.email;

  const onOpenLoginRegister = () => {
    setAnchorEl(null);
    setLoginRegisterDialogOpen(true);
    Analytics.trackNavBarMenuOpenLoginRegister();
  };

  const onCloseLoginRegister = () => {
    setLoginRegisterDialogOpen(false);
    Analytics.trackNavBarMenuCloseLoginRegister();
  };

  const onOpenEditProfile = () => {
    setAnchorEl(null);
    setEditProfileDialogOpen(true);
    Analytics.trackNavBarMenuOpenEditProfile();
  };

  const onCloseEditProfile = () => {
    setEditProfileDialogOpen(false);
    Analytics.trackNavBarMenuCloseEditProfile();
  };

  const onClickCreateNewSpace = () => {
    setAnchorEl(null);
    Analytics.trackNavBarMenuCreateNewSpace();
  };

  const onClickFeedback = () => {
    setAnchorEl(null);
    Analytics.trackNavBarMenuFeedback();
  };

  const onClickHelp = () => {
    setAnchorEl(null);
    Analytics.trackNavBarMenuHelp();
  };

  const onOpenInstructions = () => {
    setAnchorEl(null);
    setInstructionsDialogOpen(true);
    Analytics.trackNavBarMenuOpenInstructions();
  };

  const onCloseInstructions = () => {
    setInstructionsDialogOpen(false);
    Analytics.trackNavBarMenuCloseInstructions();
  };

  const onClickLogout = async () => {
    setAnchorEl(null);
    Analytics.trackAccountMenuLogout();

    await logout();
  };

  return (
    <>
      <IconButton
        aria-label="menu button"
        className={open ? classes.menuButtonOpen : classes.menuButtonClosed}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <More />
      </IconButton>
      <Menu
        autoFocus={false}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={open}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
        variant="menu"
      >
        <MenuList subheader={isLoggedIn ? <ListSubheader>{email}</ListSubheader> : undefined}>
          {!isLoggedIn && (
            <MenuItem button onClick={onOpenLoginRegister}>
              {t('navBarMenu.loginRegister')}
            </MenuItem>
          )}
          <MenuItem button onClick={onOpenEditProfile}>
            {t('navBarMenu.editProfile')}
          </MenuItem>
          {!!props.spaceId && (
            <MenuItem
              component={Link}
              onClick={onClickCreateNewSpace}
              target="_blank"
              href={'/'}
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              {t('navBarMenu.createNewSpace')}
            </MenuItem>
          )}
          <NavBarSpacesSubMenu spaceId={props.spaceId} onClose={() => setAnchorEl(null)} />
          <MenuItem
            component={Link}
            onClick={onClickFeedback}
            target="_blank"
            href={FEEDBACK_URL}
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            {t('navBarMenu.feedback')}
          </MenuItem>
          <MenuItem
            component={Link}
            onClick={onClickHelp}
            target="_blank"
            href={SUPPORT_URL}
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            {t('navBarMenu.help')}
          </MenuItem>
          {!!props.spaceId && props.showInstructions && (
            <MenuItem button onClick={onOpenInstructions}>
              {t('navBarMenu.instructions')}
            </MenuItem>
          )}
          {isLoggedIn && (
            <MenuItem button onClick={onClickLogout}>
              {t('navBarMenu.logout')}
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      <LoginRegisterDialog open={loginRegisterDialogOpen} onClose={onCloseLoginRegister} />
      <SocialProfileDialog open={editProfileDialogOpen} onClose={onCloseEditProfile} />
      <InstructionsDialog open={instructionsDialogOpen} onClose={onCloseInstructions} />
    </>
  );
}
